import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from 'reduxStore/store';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ENVIRONMENT, GOOGLE_CLIENT_ID } from 'config';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import * as Sentry from "@sentry/react";
import { browserTracingIntegration } from "@sentry/react";

Sentry.init({
  dsn: 'https://3a1631f97917dd85150787d3f0f24ea0@o4507859507544064.ingest.us.sentry.io/4508989311942656',
  environment: ENVIRONMENT,
  integrations: [
    browserTracingIntegration(),
  ], tracesSampleRate: 0.1,
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <App />
      </Provider>
    </GoogleOAuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
