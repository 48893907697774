import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { eventExtraActions } from 'reduxStore/actions';
import { utils } from "common";

const INITIAL_STATE = {
  loading: false,
  error: null,
  events: {},
};

export const eventSlice = createSlice({
  name: 'config',
  initialState: INITIAL_STATE,
  reducers: {
    resetAuthSlice: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(eventExtraActions.events.fulfilled, (state, { payload }) => {
      state.events = utils.groupEventsByDate(payload);
      state.loading = false;
      state.error = null;
    });
    builder.addMatcher(
      isAnyOf(
        eventExtraActions.settings.fulfilled,
      ),
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        eventExtraActions.events.pending,
        eventExtraActions.settings.pending,
      ),
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        eventExtraActions.events.rejected,
        eventExtraActions.settings.rejected,
      ),
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});

export const { actions: authActions, reducer } = eventSlice;
export default reducer;
