import './App.scss';
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes as Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import { globals, routes } from 'common';
import { ToastContainer } from 'react-toastify';
import { Landing, ZoomConnect, Upload, MeetingConfiguration, Meetings, Rating } from 'pages';
import { Layout } from 'components';
import { useSelector } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';
import { TAB_CODES } from "./common/constants";

const RequireAuth = () => {
  const location = useLocation();
  const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);

  return isUserLoggedIn ? <Outlet/> : <Navigate to={routes.LANDING} state={{from: location}} replace/>;
};

const RedirectToMeeting = () => {
  const {id} = useParams();
  return <Navigate to={`/meetings/${id}/?tab=${TAB_CODES.SUMMARY}`} replace/>;
};


function App() {
  return (
    <div className='app'>
      <Router>
        <Switch>
          <Route path='/' element={<Layout/>}>
            <Route path={routes.LANDING} element={<Landing/>}/>
            <Route path={routes.RATING} element={<Rating/>}/>
            <Route element={<RequireAuth/>}>
              <Route path={routes.CONNECT_ZOOM} element={<ZoomConnect/>}/>
              <Route path={routes.UPLOAD} element={<Upload/>}/>
              <Route path={routes.MEETINGS} element={<Meetings/>}/>
              <Route path={routes.SUMMARY_DETAIL} element={<RedirectToMeeting/>}/>
              <Route path={routes.MEETING_DETAIL} element={<Meetings/>}/>
              <Route path={routes.MEETING_CONFIG} element={<MeetingConfiguration/>}/>
              <Route path='*' element={<Navigate to='/'/>}/>
            </Route>
          </Route>
        </Switch>
      </Router>
      <ToastContainer autoClose={globals.TOAST_TIMER}/>
    </div>
  );
}

export default App;
