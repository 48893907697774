import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'api';

const authExtraActions = {
  userDetail: createAsyncThunk('auth/userDetail', api.userDetail),
  login: createAsyncThunk('auth/login', api.login),
  googleLogin: createAsyncThunk('auth/googleLogin', api.googleLogin),
  connectZoom: createAsyncThunk('auth/connectZoom', api.connectZoom),
  disconnectZoom: createAsyncThunk('auth/disconnectZoom', api.disconnectZoom),
  disconnectGoogleMeet: createAsyncThunk('auth/disconnectGoogleMeet', api.disconnectGoogleMeet),
};

export default authExtraActions;
