import React from 'react';
import 'components/tabBar/_tabBar.scss';
import { TAB_CODES } from 'common/constants';
import { useSelector } from 'react-redux';
import { utils } from 'common';

export default function TabBar({currentTab, setCurrentTab}) {
  const {transcriptionData} = useSelector((state) => state.general);

  return (
    <div className='tabs-container'>
      <div className='tabs-bar'>
        <div className='tabs'>
          <div
            className={`tab ${currentTab === TAB_CODES.SUMMARY ? 'active' : ''}`}
            onClick={() => setCurrentTab(TAB_CODES.SUMMARY)}
          >
            Summary
          </div>
          <div
            className={`tab ${currentTab === TAB_CODES.TRANSCRIPTION ? 'active' : ''}`}
            onClick={() => setCurrentTab(TAB_CODES.TRANSCRIPTION)}
          >
            Transcript
          </div>
          {transcriptionData?.mom && !utils.isObjectEmpty(transcriptionData?.mom) && <div
            className={`tab ${currentTab === TAB_CODES.MOM ? 'active' : ''}`}
            onClick={() => setCurrentTab(TAB_CODES.MOM)}
          >
            Minutes of meeting
          </div>}
          {transcriptionData?.reminders?.length > 0 &&  <div
            className={`tab ${currentTab === TAB_CODES.REMINDERS ? 'active' : ''}`}
            onClick={() => setCurrentTab(TAB_CODES.REMINDERS)}
          >
            Reminders
          </div>}
        </div>
      </div>
    </div>
  );
}
