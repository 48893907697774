import { IconClock, IconDocument, IconGoogleMeet, IconSparkles, IconZoom } from 'assets/icons';
import React, { useState } from 'react';
import Toggle from 'components/toggle/Toggle';
import { routes, utils } from 'common';
import {
  EVENT_ACTION_CODES,
  EVENT_STATUS_CODES,
  EVENT_TYPE_CODES,
  FILE_TYPE_CODES,
  FILE_TYPES,
  TAB_CODES,
} from 'common/constants';
import { authExtraActions, eventExtraActions } from 'reduxStore/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getEvents } from 'pages/meetingConfig/helpers';
import { Link } from 'react-router-dom';
import { CustomModal } from '../../components';
import { MeetingSettingsModal, Tooltip } from 'components';
import { toast } from 'react-toastify';
import { IconMeetingSettings, IconMeetingSettingsOrganizer } from 'assets/icons';

function Event({ event }) {
  const dispatch = useDispatch();
  const eventStatus = utils.getEventStatus(event.start_at, event.end_at);
  const isPastEvent = eventStatus === EVENT_STATUS_CODES.PAST;
  const isDisabled = event.is_disabled;
  const fileType = event.summary_format;
  const { user } = useSelector((state) => state.auth);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const handleDisableChange = () => {
    try {
      dispatch(
        eventExtraActions.status({
          id: event.id,
          data: {
            action: !isDisabled ? EVENT_ACTION_CODES.DISABLE : EVENT_ACTION_CODES.ENABLE,
            recurrence: event.is_recurring ? event.start_at : undefined,
          },
        })
      ).then((response) => {
        if (response?.type === 'auth/status/fulfilled') {
          dispatch(authExtraActions.userDetail(user.id));
          getEvents(dispatch);
          toast.success(
            isDisabled
              ? 'Textalize bot enabled for this meeting'
              : 'Textalize bot disabled for this meeting'
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
    setShowStatusModal(false);
  };

  const handleMeetingSettingsChange = (data) => {
    try {
      dispatch(
        eventExtraActions.settings({
          id: event.id,
          data: {
            summary_format: data.selectedSummaryOption,
            summary_share_option: data.selectedSharingOption,
            summary_share_emails: data.selectedEmails,
            summary_share_domains: data.selectedDomains,
            auto_share_summary: data.autoShareSummary,
            recurrence: event.is_recurring ? event.start_at : undefined,
          },
        })
      ).then((response) => {
        if (response?.type === 'auth/settings/fulfilled') {
          getEvents(dispatch);
          toast.success('Meeting settings updated');
        }
      });
    } catch (error) {
      console.log(error);
    }
    setShowSettingsModal(false);
  };

  return (
    <div className={`event ${isPastEvent ? 'faded' : ''}`}>
      <div className='e-detail'>
        <div className='e-header'>
          <span className='e-name'>{event.title}</span>
          <span
            className={`e-type ${fileType === FILE_TYPE_CODES.MEETING || fileType === FILE_TYPE_CODES.ALL_HANDS ? 'green' : 'purple'}`}
          >
            {FILE_TYPES[fileType]}
          </span>
        </div>
        <div className='e-time'>
          <img src={IconClock} alt='clock' />
          {`${utils.convertDTStrToTime(event.start_at)} - ${utils.convertDTStrToTime(event.end_at)}`}
        </div>
      </div>
      <div className='e-controls'>
        {isPastEvent ? (
          event.audio_transcription_id ? (
            <>
              <Link
                className='resources'
                to={`${routes.MEETINGS}/${event.audio_transcription_id}/`}
              >
                <img src={IconDocument} alt='transcript' />
                <span>Transcript</span>
              </Link>
              <Link
                className='resources'
                to={`${routes.MEETINGS}/${event.audio_transcription_id}/?tab=${TAB_CODES.SUMMARY}`}
              >
                <img src={IconSparkles} alt='summary' />
                <span>Summary</span>
              </Link>
            </>
          ) : null
        ) : (
          <>
            <span
              className={`settings-icon ${event.is_organizer ? 'organizer' : ''}`}
              onClick={() => setShowSettingsModal(true)}
            >
              <Tooltip
                message={
                  <span>
                    Access to these settings is limited to the meeting host. <b></b>To request
                    changes, please contact the host.
                  </span>
                }
                trigger={!event.is_organizer}
                body={
                  <img
                    src={event.is_organizer ? IconMeetingSettingsOrganizer : IconMeetingSettings}
                    alt='Meeting Settings'
                  />
                }
              />
            </span>
            <a className='join' href={event.meeting_link} target='_blank'>
              <img
                src={event.event_type === EVENT_TYPE_CODES.GOOGLE_MEET ? IconGoogleMeet : IconZoom}
                alt='type'
              />
              <span>Join meeting</span>
            </a>
            <Toggle isDisabled={isDisabled} onChange={() => setShowStatusModal(true)} size={40} />
          </>
        )}
      </div>
      <CustomModal
        show={showStatusModal}
        title={isDisabled ? 'Enable Textalize for meeting?' : 'Disable Textalize for meeting?'}
        description={
          isDisabled
            ? 'Are you sure you want to enable the Textalize bot for this meeting? You can disable the bot again if you want.'
            : 'Are you sure you want to disable the Textalize bot for this meeting? You can enable the bot again if you want.'
        }
        handleClose={() => setShowStatusModal(false)}
        cancelBtnTitle={isDisabled ? 'Keep it disabled' : 'Keep it enabled'}
        confirmBtnTitle={isDisabled ? 'Enable' : 'Disable'}
        handleCancel={() => setShowStatusModal(false)}
        handleConfirm={handleDisableChange}
      />
      <MeetingSettingsModal
        show={showSettingsModal}
        handleClose={() => setShowSettingsModal(false)}
        handleSave={handleMeetingSettingsChange}
        isOrganizer={event.is_organizer}
        event={event}
      />
    </div>
  );
}

export default Event;
