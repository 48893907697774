import React from "react";
import Event from "pages/meetingConfig/Event";
import { utils } from "common";

function EventGroup({groupDate, events}) {
  const filteredEvents = events.filter(event => !utils.hasTimePassed(event.end_at));

  if (filteredEvents.length === 0) {
    return null;
  }

  return (
    <div className='event-group'>
      <div className='eg-date'>
        <span>{utils.convertDTStrToFormat(groupDate)}</span>
      </div>
      <div className='eg-events '>
        {filteredEvents.map((event, index) => {
            return <Event key={index} event={event}/>
          }
        )}
      </div>
    </div>
  )
}

export default EventGroup;