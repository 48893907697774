import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'api';

const eventExtraActions = {
  status: createAsyncThunk('auth/status', api.status),
  statusAll: createAsyncThunk('auth/statusAll', api.statusAll),
  events: createAsyncThunk('auth/events', api.events),
  settings: createAsyncThunk('auth/settings', api.settings),
};

export default eventExtraActions;
