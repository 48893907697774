import React from 'react';
import { Modal } from 'react-bootstrap';
import 'components/modals/_ratingModal.scss';
import { ImgHappyRating, ImgSadRating } from 'assets/images';
import { FEEDBACK_EMAIL } from 'common/constants';
import Rating from "../rating/Rating";

function RatingModal({show, handleClose, rating = 3}) {
  const isHappy = rating >= 3;

  return (<Modal className='rating-modal' show={show} onHide={handleClose} backdrop='static' keyboard={false} centered>
    <Modal.Header closeButton>
    </Modal.Header>
    <Modal.Body>
      <img src={isHappy ? ImgHappyRating : ImgSadRating}/>
      <Rating rating={rating}/>
      <div className="msg">
        {isHappy ? 'Thank you for rating the summary!' : "We're sorry the summary didn’t meet your expectations."}
      </div>
      <div className="feedback">
        {isHappy ? 'Share your feedback at ' : 'Let us know how we can improve at '}
        <a href={`mailto:${FEEDBACK_EMAIL}`}>{FEEDBACK_EMAIL}</a>
      </div>
    </Modal.Body>
  </Modal>);
}

export default RatingModal;
