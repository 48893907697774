import React, { useRef } from 'react';
import { FileInput } from 'components';
import { IconUpload } from 'assets/icons';

function UploadSection1({ formik, setFileName, setSection, handleFileChange }) {
  const fileInputRef = useRef(null);

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const selectedFile = droppedFiles[0];
      formik.setFieldValue('file', selectedFile);
      setFileName(selectedFile.name);
      setSection(1);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDivClick = (event) => {
    if (event.target !== fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div
      className='section section-1'
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onClick={handleDivClick}
    >
      <form className='upload-form'>
        <div className='file-field'>
          <div className='file-handle'>
            <FileInput ref={fileInputRef} onChange={handleFileChange} />
          </div>
        </div>
      </form>
      <img src={IconUpload} width='30' alt='upload' />
      <div className='upload-instruction'>
        Upload a video or audio file
        <span>Drag & Drop a file</span>
      </div>
      <p className='supported-types'>Supported file types: mp4, mp3</p>
    </div>
  );
}

export default UploadSection1;
