import React, { useRef } from 'react';
import 'components/customAudioPlayer/_customAudioPlayer.scss';
import { AUDIO_JUMP_TIME, AUDIO_TIME_FORMAT } from 'common/constants';
import { DownloadBtn, PlaybackSpeedControl } from 'components';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {
  PauseCircleFill,
  PlayCircleFill,
  SkipEndFill,
  SkipStartFill,
  VolumeMuteFill,
  VolumeUpFill,
} from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';

function CustomAudioPlayer({ absoluteIndex, setCurrentTime }) {
  const audioPlayer = useRef(null);
  const { transcriptionData } = useSelector((state) => state.general);
  const iconStyles = {
    size: 40,
    color: '#3c3c3c',
  };

  const updateCurrentTime = (updatedTime) => {
    setCurrentTime(updatedTime);
    if (audioPlayer.current) {
      audioPlayer.current.audio.current.currentTime = updatedTime;
    }
  }

  const goToSegment = (jumpIndex) => {
    const transcriptionLength = transcriptionData?.original_transcription?.length || 0;
    let updatedTime;
    if (absoluteIndex === transcriptionData?.original_transcription?.length && jumpIndex < absoluteIndex) {
      const segment = transcriptionData?.original_transcription[jumpIndex]
      if (segment.end_time === segment.start_time) {
        updatedTime = segment.end_time
      } else {
        updatedTime = segment.start_time + 1
      }
      updateCurrentTime(updatedTime)
    } else if (jumpIndex >= 0 && jumpIndex < transcriptionLength) {
      const nextSegment = transcriptionData?.original_transcription[jumpIndex]
      const nextStartTime = nextSegment.start_time;
      const nextEndTime = nextSegment.end_time;
      let updatedTime = nextStartTime + 1;

      if (nextStartTime + 1 === nextEndTime) {
        updatedTime = nextStartTime + 0.5;
      } else if (nextStartTime === nextEndTime) {
        updatedTime = nextStartTime
      }
      updateCurrentTime(updatedTime)
    }
  };

  return (
    <div className='audio-player'>
      <AudioPlayer
        ref={audioPlayer}
        src={transcriptionData?.audio_url}
        progressJumpStep={AUDIO_JUMP_TIME}
        timeFormat={AUDIO_TIME_FORMAT}
        autoPlay={false}
        autoPlayAfterSrcChange={false}
        showSkipControls={true}
        showJumpControls={false}
        hasDefaultKeyBindings={false}
        onClickNext={() => goToSegment(absoluteIndex + 1)}
        onClickPrevious={() => goToSegment(absoluteIndex - 1)}
        customIcons={{
          play: <PlayCircleFill {...iconStyles} />,
          pause: <PauseCircleFill {...iconStyles} />,
          next: <SkipEndFill {...iconStyles} />,
          previous: <SkipStartFill {...iconStyles} />,
          volume: <VolumeUpFill color={iconStyles.color} />,
          volumeMute: <VolumeMuteFill color={iconStyles.color} />,
        }}
        onListen={(event) => {
          setCurrentTime(event.srcElement.currentTime);
        }}
        customVolumeControls={[
          <PlaybackSpeedControl audioPlayer={audioPlayer} />,
          RHAP_UI.VOLUME,
          <DownloadBtn title={transcriptionData?.title} audioUrl={transcriptionData?.audio_url} />,
        ]}
      />
    </div>
  );
}

export default CustomAudioPlayer;
