import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import 'components/searchBar/_searchBar.scss';
import { IconSearch } from "assets/icons";


function SearchBar({placeholder, onSearch}) {
  const [query, setQuery] = useState('');

  const debouncedSearch = debounce((value) => {
    onSearch(value);
  }, 500); // 500ms delay

  const handleClear = () => {
    setQuery('');
    onSearch('');
  }

  useEffect(() => {
    debouncedSearch(query);
    return () => debouncedSearch.cancel();
  }, [query]);

  return (
    <div className="search-bar">
      <span className="search-icon">
        <img src={IconSearch} alt="search-icon"/>
      </span>
      <input
        type="text"
        placeholder={placeholder}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {query && (
        <button className="clear-button" onClick={handleClear}>
          ✖
        </button>
      )}
    </div>
  );
};

export default SearchBar;
