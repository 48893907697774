import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function ToolTip({ trigger, message, body }) {
  return (
    <OverlayTrigger
      placement='top'
      trigger={trigger ? undefined : []}
      overlay={<Tooltip id='tooltip-top'>{message}</Tooltip>}
      popperConfig={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 16],
            },
          },
        ],
      }}
    >
      {body}
    </OverlayTrigger>
  );
}
