import React, { useState } from 'react';
import { api } from 'api';
import { useDispatch } from 'react-redux';
import { generalExtraActions } from 'reduxStore/actions';
import { generalActions } from 'reduxStore/reducers/generalSlice';

function Speaker({ transcriptionId, speaker, isActive }) {
  const dispatch = useDispatch();
  const [speakerName, setSpeakerName] = useState(speaker.name);

  const handleSpeakerNameChange = (event) => {
    setSpeakerName(event.target.value);
  };

  const handleSpeakerSave = async () => {
    try {
      dispatch(generalActions.startLoading());
      await api.transcriptionSpeakerUpdate({
        id: speaker.id,
        data: { name: speakerName },
      });
      dispatch(generalExtraActions.audioTranscriptDetail(transcriptionId));
    } catch (error) {
      dispatch(generalActions.stopLoading());
      console.log(error);
    }
  };

  return (
    <div className={`speaker ${isActive ? 'active' : ''}`}>
      <input
        type='text'
        name={`speaker-${speaker.id}`}
        value={speakerName}
        onChange={handleSpeakerNameChange}
      />
      <button
        disabled={speakerName === speaker.name || speakerName.trim() === ''}
        onClick={handleSpeakerSave}
      >
        Save
      </button>
    </div>
  );
}

export default Speaker;
