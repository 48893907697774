import { Modal } from 'react-bootstrap';

export default function CustomActionModal({ show, onClose, title, body, footer, onClick }) {
  return (
    <Modal show={show} onHide={onClose} backdrop='true' keyboard={false} onClick={onClick}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
}
