import { eventExtraActions } from 'reduxStore/actions';

export const getEvents = (dispatch, query = null, meetingTypes = null) => {
  const currentDate = new Date();

  const lowerDate = new Date();
  lowerDate.setDate(currentDate.getDate());
  const lowerBound = lowerDate.toISOString().split("T")[0];

  const upperDate = new Date();
  upperDate.setDate(currentDate.getDate() + 7);
  const upperBound = upperDate.toISOString().split("T")[0];

  let payload = {
    lower_bound: lowerBound,
    upper_bound: upperBound,
  }

  if (query) {
    payload.query = query;
  }

  if (meetingTypes) {
    payload.meeting_types = meetingTypes;
  }

  dispatch(eventExtraActions.events(payload));
};
