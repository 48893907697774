import React, { useState } from 'react';
import 'components/brickFilter/_brickFilter.scss';


const BrickFilter = ({choices, onFilterChange}) => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  const toggleFilter = (type) => {
    let updatedFilters;
    if (selectedFilters.includes(type)) {
      updatedFilters = selectedFilters.filter((item) => item !== type);
    } else {
      updatedFilters = [...selectedFilters, type];
    }
    setSelectedFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  return (
    <div className='brick-filter'>
      {Object.entries(choices).map(([key, label]) => (
        <div
          key={key}
          className={`brick ${selectedFilters.includes(key) ? 'selected' : ''}`}
          onClick={() => toggleFilter(key)}
        >
          {label}
        </div>
      ))}
    </div>
  );
};

export default BrickFilter;
