import React, { useEffect } from 'react';
import 'components/header/_header.scss';
import { Logo } from 'assets/images';
import { routes } from 'common';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authExtraActions } from 'reduxStore/actions';
import { useGoogleLogin } from '@react-oauth/google';
import { authActions } from 'reduxStore/reducers/authSlice';
import { generalActions } from 'reduxStore/reducers/generalSlice';
import { IconGoogle } from 'assets/icons';
import { Dropdown } from 'components';
import { GOOGLE_CALENDAR_SCOPE, MARKETING_SITE_URL } from 'common/constants';
import { toast } from 'react-toastify';

function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {user, isUserLoggedIn} = useSelector((state) => state.auth);

  const login = useGoogleLogin({
    onSuccess: (response) => {
      dispatch(authExtraActions.googleLogin(response)).then((response) => {
        if (response?.type === 'auth/googleLogin/fulfilled') {
          const redirectPath = location.state?.from?.pathname;
          if (redirectPath) {
            navigate(redirectPath, {replace: true});
          }else{
            navigate(routes.MEETINGS);
          }
        }
      });
    },
    scope: GOOGLE_CALENDAR_SCOPE,
    flow: 'auth-code',
  });

  const loginWithoutGMScopes = useGoogleLogin({
    onSuccess: (response) => dispatch(authExtraActions.googleLogin(response)),
    scope: GOOGLE_CALENDAR_SCOPE,
    flow: 'auth-code',
  });

  const handleLogout = () => {
    dispatch(authActions.logout());
  };

  const disconnectGoogleMeet = async () => {
    try {
      dispatch(authExtraActions.disconnectGoogleMeet()).then((response) => {
        if (response?.type === 'auth/disconnectGoogleMeet/fulfilled') {
          dispatch(authActions.logout());
          loginWithoutGMScopes();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const disconnectZoom = async () => {
    try {
      dispatch(authExtraActions.disconnectZoom()).then((response) => {
        if (response?.type === 'auth/disconnectZoom/fulfilled') {
          toast.success('Zoom Disconnected Successfully');
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(generalActions.resetData());
  }, [location]);

  return (<header>
      <div className='content-wrapper'>
        <div className='content'>
          <div className='logo'>
            <Link to={isUserLoggedIn ? routes.LANDING : MARKETING_SITE_URL}>
              <img src={Logo} alt='logo' loading='lazy'/>
            </Link>
          </div>
          {isUserLoggedIn && (
            <div className='menu'>
              <Link
                to={routes.MEETINGS}
                className={
                  [routes.MEETINGS, routes.MEETING_DETAIL].some((route) =>
                    location.pathname.includes(route)
                  )
                    ? 'active'
                    : ''
                }
              >
                My Meetings
              </Link>
              <Link
                to={routes.MEETING_CONFIG}
                className={location.pathname.includes(routes.MEETING_CONFIG) ? 'active' : ''}
              >
                Meeting Configurations
              </Link>
              <Link
                to={routes.UPLOAD}
                className={location.pathname === routes.UPLOAD ? 'active' : ''}
              >
                Upload File
              </Link>
            </div>)}
          {!isUserLoggedIn ? (<div className='login'>
              <a onClick={() => login()}>
                <img src={IconGoogle} alt='google' height={24} loading='lazy'/>
                Sign in with Google
              </a>
            </div>) : (<div className='logout'>
              {user && (<Dropdown
                  title={`${user?.first_name} ${user?.last_name}`}
                  options={[<a onClick={handleLogout}>Logout</a>]}
                />)}
            </div>)}
        </div>
      </div>
    </header>);
}

export default Header;
