import React from 'react';
import 'components/reminders/_reminders.scss';
import { useSelector } from 'react-redux';
import { COMPLETED } from 'common/constants';

function Reminders() {
  const {transcriptionData} = useSelector((state) => state.general);

  return (<div className='reminders'>
    <div className='reminder-section'>
      <ol>
        {
          transcriptionData?.summary_status === COMPLETED && transcriptionData?.reminders?.map((reminders) => {
            return <li key={reminders.length} className='reminder'>{reminders}</li>

          })
        }
      </ol>
    </div>
  </div>);
}

export default Reminders;
