import { generalExtraActions } from 'reduxStore/actions';

export const getAudioTranscriptions = (dispatch, query = null, meetingTypes = null) => {
  let payload = {}

  if (query) {
    payload.query = query;
  }

  if (meetingTypes) {
    payload.meeting_types = meetingTypes;
  }

  dispatch(generalExtraActions.audioTranscriptList(payload));
};
