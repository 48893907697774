import React, { useState } from 'react';
import 'components/transcription/_transcription.scss';
import { CustomAudioPlayer, TranscriptionSection } from 'components';

function Transcription({id, absoluteIndex, setAbsoluteIndex}) {
  const [currentTime, setCurrentTime] = useState(0);

  return (
    <div className='transcription'>
      <TranscriptionSection
        transcriptionId={id}
        currentTime={currentTime}
        setAbsoluteIndex={setAbsoluteIndex}
        currentAbsoluteIndex={absoluteIndex}
      />
      <CustomAudioPlayer absoluteIndex={absoluteIndex} setCurrentTime={setCurrentTime}/>
    </div>
  );
}

export default Transcription;
