import React, { useEffect, useState } from 'react';
import 'pages/meetingConfig/_meetingConfiguration.scss'
import EventGroup from "pages/meetingConfig/EventGroup";
import Toggle from "components/toggle/Toggle";
import { authExtraActions, eventExtraActions } from "reduxStore/actions";
import { useDispatch, useSelector } from "react-redux";
import { CustomModal, Loader, SearchBar, BrickFilter } from "components";
import { EVENT_ACTION_CODES, FILE_TYPES } from "common/constants";
import { getEvents } from "pages/meetingConfig/helpers";
import { toast } from "react-toastify";


function MeetingConfiguration() {
  const dispatch = useDispatch();
  const {events, loading} = useSelector((state) => state.event);
  const {user} = useSelector((state) => state.auth);
  const disableAllEvents = user.disable_all_events;
  const disableAllExternalEvents = user.disable_all_external_events;
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showExternalStatusModal, setShowExternalStatusModal] = useState(false);
  const [query, setQuery] = useState('');
  const [meetingTypes, setMeetingTypes] = useState([]);

  const handleDisableAllChange = (external = false) => {
    let action;
    if (external) {
      action = !disableAllExternalEvents ? EVENT_ACTION_CODES.DISABLE : EVENT_ACTION_CODES.ENABLE;
    } else {
      action = !disableAllEvents ? EVENT_ACTION_CODES.DISABLE : EVENT_ACTION_CODES.ENABLE;
    }
    try {
      dispatch(
        eventExtraActions.statusAll({
          action: action,
          external: external,
        })
      ).then((response) => {
        if (response?.type === 'auth/statusAll/fulfilled') {
          dispatch(authExtraActions.userDetail(user.id));
          getEvents(dispatch);
          if (external) {
            toast.success(
              disableAllExternalEvents
                ? 'Textalize bot enabled for all externally scheduled meetings'
                : 'Textalize bot disabled for all externally scheduled meetings'
            );
          } else {
            toast.success(
              disableAllEvents
                ? 'Textalize bot enabled for all meetings'
                : 'Textalize bot disabled for all meetings'
            );
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
    if (external) {
      setShowExternalStatusModal(false);
    } else {
      setShowStatusModal(false);
    }
  };

  useEffect(() => {
    getEvents(dispatch, query, meetingTypes);
  }, [query, meetingTypes]);


  return (
    <div className='meeting-config'>
      {loading && <Loader/>}
      <div className='mc-header'>
        <div className='mc-title'>
          <h1>Meetings</h1>
        </div>
        <div className='mc-controls'>
          <BrickFilter choices={FILE_TYPES} onFilterChange={setMeetingTypes} />
          <SearchBar placeholder='Search for a meeting' onSearch={setQuery} />
        </div>
      </div>
      <div className='mc-header'>
        <div className='mc-controls'>
          {/* <div className='mc-disable-all'>
            <span>{`${disableAllEvents ? 'Enable' : 'Disable'}`} for all future meetings</span>
            <Toggle isDisabled={disableAllEvents} onChange={() => setShowStatusModal(true)} />
          </div> */}
          <div className='mc-disable-all'>
            <span>Allow Textalize to join external meetings</span>
            <Toggle isDisabled={disableAllExternalEvents} onChange={() => setShowExternalStatusModal(true)} />
          </div>
        </div>
      </div>
      <div className='mc-content'>
        {Object.entries(events).map(([groupDate, events], index) => {
          return <EventGroup key={index} groupDate={groupDate} events={events}/>;
        })}
      </div>
      <CustomModal
        show={showStatusModal}
        title={
          disableAllEvents
            ? 'Enable Textalize for all meetings?'
            : 'Disable Textalize for all meetings?'
        }
        description={
          disableAllEvents
            ? 'Are you sure you want to enable the Textalize bot for all of your meetings? You can disable the bot again if you want.'
            : 'Are you sure you want to disable the Textalize bot for all of your meetings? You can enable the bot again if you want.'
        }
        handleClose={() => setShowStatusModal(false)}
        cancelBtnTitle={disableAllEvents ? 'Keep it disabled' : 'Keep it enabled'}
        confirmBtnTitle={disableAllEvents ? 'Enable for all' : 'Disable for all'}
        handleCancel={() => setShowStatusModal(false)}
        handleConfirm={() => handleDisableAllChange()}
      />
      <CustomModal
        show={showExternalStatusModal}
        title={
          disableAllExternalEvents
            ? 'Enable Textalize for all external meetings?'
            : 'Disable Textalize for all external meetings?'
        }
        description={
          disableAllExternalEvents
            ? 'Are you sure you want to enable the Textalize bot for all of your externally scheduled meetings? You can disable the bot again if you want.'
            : 'Are you sure you want to disable the Textalize bot for all of your externally scheduled meetings? You can enable the bot again if you want.'
        }
        handleClose={() => setShowExternalStatusModal(false)}
        cancelBtnTitle={disableAllExternalEvents ? 'Keep it disabled' : 'Keep it enabled'}
        confirmBtnTitle={disableAllExternalEvents ? 'Enable for all' : 'Disable for all'}
        handleCancel={() => setShowExternalStatusModal(false)}
        handleConfirm={() => handleDisableAllChange(true)}
      />
    </div>
  );
}

export default MeetingConfiguration;
