export const routes = {
  LANDING: '',
  LOGIN: '/login',
  CONNECT_ZOOM: '/connect-zoom',
  UPLOAD: '/upload',
  MEETING_CONFIG: '/meeting-configuration',
  MEETINGS: '/meetings',
  MEETING_DETAIL: '/meetings/:id',
  SUMMARY_DETAIL: '/summaries/:id',
  RATING: '/meetings/:id/rating/:rating',
};
