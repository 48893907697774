import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import ContentEditable from 'react-contenteditable';
import { generalActions } from 'reduxStore/reducers/generalSlice';
import { IconPlus, IconMinus } from 'assets/icons';
import 'components/editableSummarySection/_editableSummarySection.scss';

export default function EditableSummarySection({
  heading,
  subheading,
  data,
  editSummary,
  summary,
  setSummary,
}) {
  const dispatch = useDispatch();
  const summaryRef = useRef();

  useEffect(() => {
    summaryRef.current = summary;
  }, [summary]);


  const handleBlur = (e) => {
    const element = e.target.dataset;
    const index = element?.index;
    let subheading = element?.subheading;
    const heading = element?.heading;
    const newSummary = _.cloneDeep(summaryRef.current);
    const updatedContent = e.target.textContent;

    if (index && subheading && heading) {
      if (subheading === 'empty_title') {
        subheading = '';
      }
      const subheadingContent = newSummary[heading][subheading]
      if (subheadingContent[index] === updatedContent) {
        return;
      }
      subheadingContent[index] = updatedContent;
    } else if (subheading && heading) {
      if (updatedContent in newSummary[heading]) {
        return;
      }
      const newObj = {};
      Object.keys(newSummary[heading]).forEach((key) => {
        if (key === subheading || key === '') {
          if (key === '' && subheading === 'empty_title') {
            newObj[updatedContent] = _.cloneDeep(newSummary[heading]['']);
            delete newSummary[heading][''];
          } else {
            newObj[updatedContent] = _.cloneDeep(newSummary[heading][subheading]);
          }
        } else if (key !== '') {
          newObj[key] = newSummary[heading][key];
        }
      });
      newSummary[heading] = newObj;
    } else {
      if (newSummary[heading] === updatedContent) {
        return;
      }
      newSummary[heading] = updatedContent;
    }

    dispatch(
      generalActions.updateTranscriptionSummary({
        newSummaryData: newSummary,
      })
    );
    setSummary(newSummary);
  };

  const handleAddItem = () => {
    const newSummary = _.cloneDeep(summaryRef.current);
    newSummary[heading][subheading].push('');
    setSummary(newSummary);
  };

  const handleDeleteSubHeading = (subheading) => {
    const newSummary = _.cloneDeep(summaryRef.current);
    delete newSummary[heading][subheading];
    dispatch(
      generalActions.updateTranscriptionSummary({
        newSummaryData: newSummary,
      })
    );
    setSummary(newSummary);
  };

  const getSummarySectionContent = () => {
    if (typeof data === 'object') {
      return (
        <div className='summary-sub-text subheading-section'>
          {editSummary ? (
            <span className='edit-symbol remove' onClick={() => handleDeleteSubHeading(subheading)}>
              <img src={IconMinus} alt='Remove' />
            </span>
          ) : null}
          <ContentEditable
            onBlur={(e) => handleBlur(e)}
            html={subheading}
            className={`summary-head ${editSummary ? 'contenteditable' : ''}`}
            data-heading={heading}
            data-subheading={subheading || 'empty_title'}
            disabled={!editSummary}
            data-placeholder='Title'
          />
          {data?.map((item, index) => {
            return (
              <ContentEditable
                key={`${index}-${subheading}`}
                onBlur={(e) => handleBlur(e)}
                html={item}
                className={`summary-sub-text ${editSummary ? 'contenteditable' : ''}`}
                data-index={index}
                data-heading={heading}
                data-subheading={subheading || 'empty_title'}
                disabled={!editSummary}
                data-placeholder='Type something...'
              />
            );
          })}
          {editSummary ? (
            <div className='add-item' onClick={() => handleAddItem()}>
              <span className='edit-symbol'>
                <img src={IconPlus} alt='Add' />
              </span>
              Add Item
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <ContentEditable
          onBlur={(e) => handleBlur(e)}
          html={data}
          className={`summary-sub-text ${editSummary ? 'contenteditable' : ''}`}
          data-heading={heading}
          data-subheading={subheading}
          disabled={!editSummary}
          data-placeholder='Description'
        />
      );
    }
  };

  return <>{getSummarySectionContent()}</>;
}
