import React from 'react';
import 'components/progressBar/_progressBar.scss';

function ProgressBar({ progress, classes = '' }) {
  return (
    <div className={`progress-bar-container ${classes}`}>
      <div className='progress-text'>{`${progress}%`}</div>
      <div className='progress-bar' style={{ width: `${progress}%` }}></div>
    </div>
  );
}

export default ProgressBar;
