import React from 'react';
import { MAX_RATING } from 'common/constants';
import { Star, StarFill } from 'react-bootstrap-icons';
import 'components/rating/_rating.scss';

function Rating({rating, size=20}) {
  return (
    <div className="rating">
      {[...Array(MAX_RATING)].map((_, index) => {
        const starValue = index + 1;
        return starValue > rating ? <Star fill={'#697586'} key={starValue} size={size}/> :
          <StarFill fill={'#6938EF'} key={starValue} size={size}/>;
      })}
    </div>
  );
}

export default Rating;