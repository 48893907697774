import { errorsCodes } from 'common/errors';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import {
  ALL_HAND_WORDS,
  FILE_TYPES, INTERVIEW_WORDS,
  LOGO_BASE64, STANDUP_WORDS,
  STATE_FETCH_INTERVAL,
  VIDEO_CONVERSION_BUFFER,
  VIDEO_CONVERSION_TIME_PER_MB,
  FILE_TYPE_CODES, EVENT_STATUS_CODES, LANGUAGES
} from 'common/constants';
import { format, parseISO } from "date-fns";
import { AMIRI_FONT } from "common/fonts/AmiriFont";

export const utils = {
  getErrorString: (error) => {
    let textError = '';
    const arr = Object.values(error.response.data).flat();
    for (let i = 0; i < arr.length; i += 1) {
      textError += `${arr[i]}\n`;
    }
    return textError;
  },
  showErrorToast: (error) => {
    if (error.response?.status === errorsCodes.SERVER_ERROR) {
      toast.error(error.response.statusText);
    }

    if (error.response?.data) {
      toast.error(utils.getErrorString(error));
    } else {
      toast.error(error.message ? error.message : error);
    }
  },
  toArrayMapping: (data) => {
    return data.reduce((dict, item) => {
      dict[item.id] = item;
      return dict;
    }, {});
  },
  hasTimePassed: (date) => {
    const eventDate = new Date(date);
    const now = new Date();
    return eventDate < now;
  },
  toArrayWithKey: (data) => {
    return data
      ? Object.entries(data)?.map(([key, value]) => ({
        ...value,
        key,
      }))
      : [];
  },
  removeItemsFromArray: (arr, target) => {
    return arr.filter((item) => item !== target);
  },
  timeStringToSeconds: (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':')?.map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  },
  secondsToTimeString: (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  },
  binarySearch: (segments, currentTime, currentAbsoluteIndex) => {
    let low = 0;
    let high = segments.length - 1;

    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      if (currentTime > segments[mid].end_time) {
        low = mid + 1;
      } else if (currentTime < segments[mid].start_time) {
        high = mid - 1;
      } else {
        if (segments[currentAbsoluteIndex]?.end_time < currentTime) { // moving forward
          if ( // handles scenario where two segments have same starting time
            segments[mid].start_time === currentTime &&
            segments[mid].start_time === segments[mid].end_time
          ) {
            if (mid === currentAbsoluteIndex) {
              return mid + 1
            }
            return mid;
          } else if (mid - 1 === currentAbsoluteIndex) {
            return mid;
          } else if (mid === currentAbsoluteIndex) {
            return mid+1;
          } else {
            if (currentTime > segments[mid].start_time && currentTime < segments[mid].end_time) {
              return mid;
            }
            return mid-1;
          }
        } else if (segments[currentAbsoluteIndex]?.start_time >= currentTime) { // moving backward
          if (segments[mid].start_time === currentTime) { // handles scenario where two segments have same starting time
            if (mid === currentAbsoluteIndex) {
              return mid - 1
            } else {
              return mid
            }
          } else if (mid + 1 === currentAbsoluteIndex) {
            return mid;
          } else if (mid === currentAbsoluteIndex) {
            return mid-1
          } else {
            if (currentTime > segments[mid].start_time && currentTime < segments[mid].end_time) {
              return mid;
            }
            return mid+1;
          }
        } else {
          return mid;
        }
      }
    }
    return low;
  },
  getNewSegmentIndex: (transcriptionSegments, currentTime, absoluteIndex) => {
    if (!transcriptionSegments || transcriptionSegments.length === 0) {
      return 0;
    }

    let currentIndex = utils.binarySearch(transcriptionSegments, currentTime, absoluteIndex);
    currentIndex = currentIndex === -1 ? transcriptionSegments.length - 1 : currentIndex;
    return currentIndex;
  },
  getRelevantSegments: (transcriptionSegments, currentTime, absoluteIndex) => {
    if (!transcriptionSegments || transcriptionSegments.length === 0) {
      return [[], 0];
    }

    let currentIndex = utils.getNewSegmentIndex(transcriptionSegments, currentTime, absoluteIndex);
    const start = Math.max(0, currentIndex - 3);
    const end = Math.min(transcriptionSegments.length, currentIndex + 3);
    return [transcriptionSegments.slice(start, end), currentIndex - start, currentIndex];
  },
  removeSegmentsByIndices: (segments, indices) => {
    return segments.filter((_, index) => !indices.includes(index));
  },
  groupConsecutiveIndices: (indices) => {
    const groups = [];
    let group = [indices[0]];

    for (let i = 1; i < indices.length; i++) {
      if (indices[i] === indices[i - 1] + 1) {
        group.push(indices[i]);
      } else {
        groups.push(group);
        group = [indices[i]];
      }
    }
    groups.push(group);
    return groups;
  },
  sortArray: (data) => {
    return data.sort((item1, item2) => item1 - item2);
  },
  mergeSegmentsByIndices: (segments, indices) => {
    const sortedIndices = utils.sortArray(indices);
    const groupedIndices = utils.groupConsecutiveIndices(sortedIndices);

    groupedIndices.forEach((group) => {
      if (group.length < 2) return;

      const segmentsToMerge = group.map((index) => segments[index]);
      const mergedText = segmentsToMerge.map((segment) => segment.text).join(' ');
      const startTime = segmentsToMerge[0].start_time;
      const endTime = segmentsToMerge[segmentsToMerge.length - 1].end_time;

      // todo: assuming that speaker for segments is same
      segments[group[0]] = {
        text: mergedText,
        speaker: segmentsToMerge[0].speaker,
        start_time: startTime,
        end_time: endTime,
        key: `${utils.secondsToTimeString(startTime)} - ${utils.secondsToTimeString(endTime)}`,
      };

      for (let i = group.length - 1; i > 0; i--) {
        segments.splice(group[i], 1);
      }
    });

    return segments;
  },
  convertToSRT: (transcriptionSegments, speakerMap) => {
    return transcriptionSegments
      .map((segment, index) => {
        const startTime = utils.secondsToTimeString(segment.start_time);
        const endTime = utils.secondsToTimeString(segment.end_time);
        return `${index + 1}\n${startTime},000 --> ${endTime},000\n(${speakerMap[segment.speaker].name}) ${segment.text}\n`;
      })
      .join('\n');
  },
  downloadSRT: (filename, srtContent) => {
    const blob = new Blob([srtContent], {type: 'text/plain'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  },
  handleTranscriptionExport: (transcriptionSegments, speakerMap) => {
    const srtContent = utils.convertToSRT(transcriptionSegments, speakerMap);
    utils.downloadSRT('transcription.srt', srtContent);
  },
  getPDF: (title, fileType, duration, speakers) => {
    const doc = new jsPDF();

    doc.addFileToVFS('Amiri-Regular.ttf', AMIRI_FONT);
    doc.addFont('Amiri-Regular.ttf', 'Amiri-Regular', 'normal');

    doc.setFontSize(12);

    doc.addImage(LOGO_BASE64, 'JPEG', 26, 20, 24, 20);

    const startY = 55;
    // Set fonts and add text sections
    doc.setFont('helvetica', 'normal', 'bold');
    doc.text('Meeting Title:', 20, startY);

    doc.setFont('helvetica', 'normal', 'normal');
    doc.text(title || '', 50, startY);

    doc.setFont('helvetica', 'normal', 'bold');
    doc.text('Meeting Type:', 20, startY + 10);

    doc.setFont('helvetica', 'normal', 'normal');
    doc.text(FILE_TYPES[fileType] || '', 50, startY + 10);

    doc.setFont('helvetica', 'normal', 'bold');
    doc.text('Meeting Duration:', 20, startY + 20);

    doc.setFont('helvetica', 'normal', 'normal');
    doc.text(duration || '', 58, startY + 20);

    doc.setFont('helvetica', 'normal', 'bold');
    doc.text('Speakers:', 20, startY + 30);

    doc.setFont('helvetica', 'normal', 'normal');
    const speakerLines = doc.splitTextToSize(
      (speakers?.map((speaker) => speaker.name) || []).join(', '),
      125
    );
    let y = startY + 30;
    speakerLines.forEach((line) => {
      if (y > 275) {
        doc.addPage();
        y = 25;
      }
      doc.text(line, 43, y);
      y += 5;
    });

    return [doc, y];
  },
  generateTranscriptionPDF: (transcriptionData) => {
    let transcription = transcriptionData?.original_transcription;
    let speakerMap = transcriptionData?.speakerMap;
    let transcriptionStr = '';
    for (let i = 0; i < transcriptionData?.original_transcription.length; i++) {
      transcriptionStr += `(${speakerMap[transcription[i].speaker].name}) ${transcription[i].text}\n\n`;
    }
    let [doc, y] = utils.getPDF(
      transcriptionData?.title,
      transcriptionData?.file_type,
      transcriptionData?.audio_duration,
      transcriptionData?.speakers,
    );

    y += 10;
    doc.setFont('helvetica', 'normal', 'bold');
    doc.setFontSize(14);
    doc.text('Meeting Notes:', 20, y);

    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal', 'normal');
    const lines = doc.splitTextToSize(transcriptionStr, 170);
    y += 10;

    lines.forEach((line) => {
      if (y > 275) {
        doc.addPage();
        y = 25;
      }

      let x = 20;
      const words = line.split(/(\s+)/); // Split by spaces, keeping spaces

      let englishBuffer = [];
      let urduBuffer = [];
      let lastLang = LANGUAGES.ENGLISH; // Track last used language

      const renderBuffer = (buffer, font, reverse = false) => {
        if (buffer.length > 0) {
          if (reverse) buffer.reverse(); // Reverse Urdu words for proper RTL rendering

          doc.setFont(font, 'normal', 'normal');
          buffer.forEach((word) => {
            doc.text(word, x, y);
            x += doc.getTextWidth(word);
          });

          buffer.length = 0; // Clear the buffer after rendering
        }
      };

      words.forEach((word) => {
        if (/^\s+$/.test(word)) {
          // If it's a space, add it to the last used language
          if (lastLang === LANGUAGES.URDU) {
            urduBuffer.push(word);
          } else {
            englishBuffer.push(word);
          }
        } else if (/[\u0600-\u06FF]/.test(word)) {
          renderBuffer(englishBuffer, 'helvetica');
          urduBuffer.push(word);
          lastLang = LANGUAGES.URDU;
        } else {
          renderBuffer(urduBuffer, 'Amiri-Regular', true);
          englishBuffer.push(word);
          lastLang = LANGUAGES.ENGLISH;
        }
      });

      renderBuffer(englishBuffer, 'helvetica');
      renderBuffer(urduBuffer, 'Amiri-Regular', true);

      y += 5; // Move to the next line
    });

    doc.save('transcription.pdf');
  },
  generateSummaryPDF: (summaryData) => {
    let [doc, y] = utils.getPDF(
      summaryData?.title,
      summaryData?.file_type,
      summaryData?.audio_duration,
      summaryData?.speakers
    );

    const renderSummaryItemsToPDF = (summary) => {
      if (!summary) {
        return null;
      } else if (Array.isArray(summary)) {
        summary.map((item) => {
          y += 2;
          renderSummaryItemsToPDF(item);
        });
      } else if (typeof summary === 'object') {
        y += 10;
        Object.entries(summary).map(([key, value]) => {
          doc.setFont('helvetica', 'normal', 'bold');
          doc.text(`${utils.snakeToTitleCase(key)}:`, 20, y);
          renderSummaryItemsToPDF(value);
          y += 10;
        });
      } else {
        doc.setFont('helvetica', 'normal', 'normal');
        const lines = doc.splitTextToSize(summary, 170); // Adjust width as needed
        lines.forEach((line) => {
          if (y > 275) {
            // Check for page overflow
            doc.addPage();
            y = 25;
          }
          y += 6;
          doc.text(line, 20, y);
        });
      }
    };

    y += 10;

    doc.setFont('helvetica', 'normal', 'bold');
    doc.setFontSize(14);
    doc.text('Summary:', 20, y);

    doc.setFontSize(12);
    renderSummaryItemsToPDF(utils.parseJSONData(summaryData?.summary));
    doc.save('summary.pdf');
  },
  ellipseText: (text, noOfChar) => {
    return text.length > noOfChar ? `${text.substring(0, 11)}...` : text;
  },
  snakeToTitleCase: (snakeStr) => {
    return snakeStr
      .split('_') // Split the string by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  },
  mapProgress: (progress, min, max) => {
    const newProgress = Math.round((progress * (max - min)) / 100 + min);
    return newProgress > max ? max : newProgress;
  },
  getEstimateConversionTime: (fileSize) => {
    return (
      Math.round((fileSize / (1024 * 1024)) * VIDEO_CONVERSION_TIME_PER_MB) +
      VIDEO_CONVERSION_BUFFER
    ); // fileSize in bytes to MB
  },
  isObjectEmpty: (obj) => {
    return (
      obj === null ||
      obj === undefined ||
      (Object.keys(obj).length === 0 && obj.constructor === Object)
    );
  },
  getStatusFetchInterval: (estimatedTime) => {
    const fetchInterval = estimatedTime || 0 / 10;
    return (
      Math.round(fetchInterval > STATE_FETCH_INTERVAL ? fetchInterval : STATE_FETCH_INTERVAL) * 1000
    );
  },
  parseJSONData: (data) => {
    let parsedData;
    try {
      parsedData = typeof data === 'string' ? JSON.parse(data) : data;
    } catch (e) {
      parsedData = {};
    }
    return parsedData;
  },
  sanitizeSummaryContent: (input, emptyObj = { key: '', value: '' }) => {
    if (Array.isArray(input)) {
      return input.filter(item => item !== '')
    } else if (input !== null && typeof input === 'object') {
      const result = {};
      for (const key in input) {
        if (input.hasOwnProperty(key)) {
          if (key === '') continue;
          let value = input[key];
          if (value !== null && typeof value === 'object') {
            value = utils.sanitizeSummaryContent(value, emptyObj);
            if (JSON.stringify(value) === JSON.stringify(emptyObj)) {
              continue;
            }
          }
          result[key] = value;
        }
      }
      return result;
    }
    return input;
  },
  getIndefiniteArticle: (word) => {
    if (!word) return '';
    const vowelRegex = /^[aeiou]/i;
    return vowelRegex.test(word) ? 'an' : 'a';
  },
  singularize: (word) => {
    if (typeof word !== 'string') return word;
    if (word.endsWith('ies')) {
      return word.slice(0, -3) + 'y';
    }
    if (word.endsWith('s') && !word.endsWith('ss')) {
      return word.slice(0, -1);
    }
    return word;
  },
  convertDTStrToFormat: (dateString, targetFormat = 'EEEE, MMMM do') => {
    return format(new Date(dateString), targetFormat);
  },
  convertDTStrToTime: (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  },
  convertDTStrToDT: (dateString) => {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${hours}:${minutes} on ${month}/${day}/${year}`;
  },
  getFileTypeFromTitle: (title) => {
    title = title.trim().toLowerCase();

    if (INTERVIEW_WORDS.some(word => title.includes(word))) {
      return FILE_TYPE_CODES.INTERVIEW;
    } else if (STANDUP_WORDS.some(word => title.includes(word))) {
      return FILE_TYPE_CODES.STANDUP;
    } else if (ALL_HAND_WORDS.some(word => title.includes(word))) {
      return FILE_TYPE_CODES.ALL_HANDS;
    } else {
      return FILE_TYPE_CODES.MEETING;
    }
  },
  getEventStatus: (startAt, endAt) => {
    const now = new Date();
    const startTime = new Date(startAt);
    const endTime = new Date(endAt);

    if (now >= startTime && now <= endTime) {
      return EVENT_STATUS_CODES.ONGOING;
    } else if (now < startTime) {
      return EVENT_STATUS_CODES.UPCOMING;
    } else {
      return EVENT_STATUS_CODES.PAST;
    }
  },
  groupEventsByDate: (events) => {
    const convertToUserTimezone = (dateString, userTimezone) => {
      const date = parseISO(dateString); // Parse into Date object
      return format(date, "yyyy-MM-dd", {timeZone: userTimezone}); // Convert to user's timezone
    };

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return events.reduce((grouped, event) => {
      const eventDate = convertToUserTimezone(event.start_at, userTimezone); // Convert to user's timezone
      if (!grouped[eventDate]) {
        grouped[eventDate] = [];
      }
      grouped[eventDate].push(event);
      return grouped;
    }, {});
  },
};
