import React from 'react';

function DownloadBtn({ title, audioUrl }) {
  const downloadAudio = () => {
    const link = document.createElement('a');
    link.href = audioUrl;
    link.setAttribute('download', `${title || 'audio'}.mp3`);
    link.target = '_blank'; // Open in a new tab to handle signed URLs properly
    document.body.appendChild(link); // Append to the DOM
    link.click();
    document.body.removeChild(link); // Clean up the DOM
  };

  return (
    <a className='download customPrimaryBtn' href={audioUrl} target='_blank'>
      Download
    </a>
  );
}

export default DownloadBtn;
