import { useState } from 'react';
import { MeetingSettingsDropdown } from 'components';
import { IconClose } from 'assets/icons';
import {
  SUMMARY_SHARING_OPTIONS,
  SUMMARY_SHARING_CODES,
  EMAIL_VALIDATION_REGEX,
  DOMAIN_VALIDATION_REGEX,
} from 'common/constants';
import 'components/meetingNotesShareSetting/_meetingNotesShareSetting.scss';

export default function MeetingNotesShareSetting({
  active,
  settings,
  setSettings,
  error,
  setError,
  showOptions,
  setShowOptions,
}) {
  const [domainInputValue, setDomainInputValue] = useState('');
  const [emailInputValue, setEmailInputValue] = useState('');

  const updateSettings = (updates) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      ...updates,
    }));
  };

  const handleSharingOptionChange = (option) => {
    setError('');
    updateSettings({ selectedSharingOption: parseInt(option) });
    setShowOptions(false);
  };

  const removeSelectedEmail = (email) => {
    updateSettings({
      selectedEmails: settings?.selectedEmails.filter((item) => item !== email),
    });
  };

  const removeSelectedDomain = (domain) => {
    updateSettings({
      selectedDomains: settings?.selectedDomains.filter((item) => item !== domain),
    });
  };

  const handleEmailInputKeyPress = (e) => {
    setError('');
    const isValidEmail = EMAIL_VALIDATION_REGEX.test(String(emailInputValue).toLowerCase());
    if (e.key === 'Enter' && isValidEmail) {
      updateSettings({
        selectedEmails: [...new Set([...settings?.selectedEmails, emailInputValue.trim()])],
      });
      setEmailInputValue('');
    }
  };

  const handleDomainInputKeyPress = (e) => {
    setError('');
    const isValidDomain = DOMAIN_VALIDATION_REGEX.test(domainInputValue);
    if (e.key === 'Enter' && domainInputValue.trim() !== '' && isValidDomain) {
      updateSettings({
        selectedDomains: [...new Set([...settings?.selectedDomains, domainInputValue.trim()])],
      });
      setDomainInputValue('');
    }
  };

  return (
    <div className={`sharing-options ${active ? '' : 'disabled'}`}>
      <p>Share meeting notes Email with</p>
      <MeetingSettingsDropdown
        selectedOption={settings?.selectedSharingOption}
        showOptions={showOptions}
        dropDownOptions={SUMMARY_SHARING_OPTIONS}
        handleOptionChange={handleSharingOptionChange}
        selectedOptionClick={(e) => {
          e.stopPropagation();
          setShowOptions((prevState) => !prevState);
        }}
      />
      {settings?.selectedSharingOption === SUMMARY_SHARING_CODES.CUSTOM ? (
        <>
          <div className='custom-sharing-option'>
            <input
              placeholder='Enter email address'
              value={emailInputValue}
              onKeyDown={(e) => handleEmailInputKeyPress(e)}
              onChange={(e) => setEmailInputValue(e.target.value)}
            />
            <span className={`${emailInputValue.length > 0 ? 'show' : ''}`}>Press Enter</span>
          </div>
          <div className='selected-emails'>
            {settings?.selectedEmails.map((value) => {
              return (
                <span className='item'>
                  <span>{value}</span>
                  <span className={'close'} onClick={() => removeSelectedEmail(value)}>
                    <img src={IconClose} alt='Close Icon' />
                  </span>
                </span>
              );
            })}
            <p className='error'>{error}</p>
          </div>
        </>
      ) : null}
      {settings?.selectedSharingOption === SUMMARY_SHARING_CODES.DOMAIN_WISE_ATTENDEES ? (
        <>
          <div className='domain-sharing-option'>
            <input
              placeholder='Enter domains'
              value={domainInputValue}
              onKeyDown={(e) => handleDomainInputKeyPress(e)}
              onChange={(e) => setDomainInputValue(e.target.value)}
            />
            <span className={`${domainInputValue.length > 0 ? 'show' : ''}`}>Press Enter</span>
          </div>
          <div className='selected-domains'>
            {settings?.selectedDomains.map((value) => {
              return (
                <span className='item'>
                  <span>{value}</span>
                  <span className={'close'} onClick={() => removeSelectedDomain(value)}>
                    <img src={IconClose} alt='Close Icon' />
                  </span>
                </span>
              );
            })}
            <p className='error'>{error}</p>
          </div>
        </>
      ) : null}
    </div>
  );
}
