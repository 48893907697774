import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IconInfo } from 'assets/icons';
import { SUMMARY_SHARING_CODES, FILE_TYPES } from 'common/constants';
import 'components/meetingSettingsModal/_meetingSettingsModal.scss';
import { MeetingNotesShareSetting, MeetingSettingsDropdown } from 'components';

export default function MeetingSettingsModal({
  show,
  handleClose,
  handleSave,
  isOrganizer,
  event,
}) {
  const [showSharingOptions, setShowSharingOptions] = useState(false);
  const [showSummaryOptions, setShowSummaryOptions] = useState(false);
  const [settings, setSettings] = useState({
    autoShareSummary: event.auto_share_summary,
    selectedSharingOption: event.summary_share_option,
    selectedSummaryOption: event.summary_format,
    selectedEmails: event.summary_share_emails,
    selectedDomains: event.summary_share_domains,
  });
  const [error, setError] = useState('');

  const updateSettings = (updates) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      ...updates,
    }));
  };

  const handleSummaryOptionChange = (option) => {
    setError('');
    updateSettings({ selectedSummaryOption: option });
    setShowSummaryOptions(false);
  };

  const onCloseHandler = () => {
    setShowSharingOptions(false);
    setShowSummaryOptions(false);
    setError('');
    handleClose();
  };

  const onToggleHandler = () => {
    updateSettings({ autoShareSummary: !settings.autoShareSummary });
    setError('');
  };

  const onSaveHandler = () => {
    let newSharingOption = settings.selectedSharingOption;
    let shareEmails = settings.selectedEmails;
    let shareDomains = settings.selectedDomains;

    if (
      settings.selectedSharingOption === SUMMARY_SHARING_CODES.CUSTOM &&
      shareEmails.length === 0
    ) {
      if (settings.autoShareSummary) {
        setError('Enter email adddress for sharing meeting notes');
        return;
      } else {
        newSharingOption = event.summary_share_option;
        shareEmails = event.summary_share_emails;
        updateSettings({ selectedSharingOption: newSharingOption, selectedEmails: shareEmails });
      }
    }

    if (
      settings.selectedSharingOption === SUMMARY_SHARING_CODES.DOMAIN_WISE_ATTENDEES &&
      shareDomains.length === 0
    ) {
      if (settings.autoShareSummary) {
        setError('Enter domains for specifying invitees for sharing meeting notes');
        return;
      } else {
        newSharingOption = event.summary_share_option;
        updateSettings({ selectedSharingOption: newSharingOption, selectedDomains: shareDomains });
        shareDomains = event.selectedDomains;
      }
    }

    const data = {
      selectedSummaryOption: settings.selectedSummaryOption,
      selectedSharingOption: newSharingOption,
      selectedEmails: shareEmails,
      selectedDomains: shareDomains,
      autoShareSummary: settings.autoShareSummary,
    };
    handleSave(data);
  };

  const onClickHandler = () => {
    setShowSharingOptions((prevState) => {
      if (prevState === true) {
        return false;
      }
    });
    setShowSummaryOptions((prevState) => {
      if (prevState === true) {
        return false;
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={onCloseHandler}
      backdrop='true'
      keyboard={false}
      onClick={onClickHandler}
    >
      <Modal.Header closeButton>
        <Modal.Title className='modal-title'>{'Preferences'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='settings-modal-body'>
          {!isOrganizer ? (
            <div className='not-organizer-message'>
              <div className='info-symbol'>
                <span>
                  <img src={IconInfo} alt='Info' />
                </span>
              </div>
              <div className='info-message'>
                <p className='first'>Access to these settings is limited to the meeting host.</p>
                <p className='second'>To request changes, please contact the host.</p>
              </div>
            </div>
          ) : null}
          <div className={`summary-options ${isOrganizer ? '' : 'disabled'}`}>
            <p>Meeting notes format</p>
            <MeetingSettingsDropdown
              selectedOption={settings.selectedSummaryOption}
              showOptions={showSummaryOptions}
              dropDownOptions={FILE_TYPES}
              handleOptionChange={handleSummaryOptionChange}
              selectedOptionClick={(e) => {
                e.stopPropagation();
                setShowSummaryOptions((prevState) => {
                  setShowSharingOptions(false);
                  return !prevState;
                });
              }}
            />
          </div>
          <MeetingNotesShareSetting
            active={isOrganizer && settings.autoShareSummary}
            setSettings={setSettings}
            settings={settings}
            error={error}
            setError={setError}
            showOptions={showSharingOptions}
            setShowOptions={setShowSharingOptions}
          />
          <div className={`auto-share-toggle ${isOrganizer ? '' : 'disabled'}`}>
            <label className={`toggle ${settings.autoShareSummary && isOrganizer ? 'active' : ''}`}>
              <input
                type='checkbox'
                checked={settings.autoShareSummary}
                onChange={() => onToggleHandler()}
              />
              <span className='toggle-slider'></span>
            </label>
            <span>Automatically send Email after meeting</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {isOrganizer ? (
          <button className={'save-button'} onClick={onSaveHandler}>
            Save
          </button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}
