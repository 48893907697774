import { IconChevronUp, IconChevronDown } from 'assets/icons';
import 'components/meetingSettingsDropdown/_meetingSettingsDropdown.scss';

export default function MeetingSettingsDropdown({
  selectedOption,
  showOptions,
  dropDownOptions,
  handleOptionChange,
  selectedOptionClick,
}) {
  return (
    <div className='settings-dropdown'>
      <div className='selected-option' onClick={selectedOptionClick}>
        <span>{dropDownOptions[selectedOption]}</span>
        <span className={`chevron`}>
          <img src={showOptions ? IconChevronUp : IconChevronDown} alt='Chevron Icon' />
        </span>
      </div>
      <div className={`select-options ${showOptions ? 'show-dropdown' : ''}`}>
        {Object.entries(dropDownOptions).map(([key, val]) => (
          <span
            key={val}
            onClick={() => handleOptionChange(key)}
            className={selectedOption === parseInt(key) ? 'active' : ''}
          >
            {val}
          </span>
        ))}
      </div>
    </div>
  );
}
