import React, { useEffect, useState } from 'react';
import { TranscriptionSegment } from 'components';
import { useSelector } from 'react-redux';
import { utils } from 'common';
import { COMPLETED } from 'common/constants';
import 'components/transcriptionSection/_transcriptionSection.scss';

function TranscriptionSection({transcriptionId, currentTime, setAbsoluteIndex, currentAbsoluteIndex}) {
  const [selectedSegments, setSelectedSegments] = useState([]);
  const {transcriptionData} = useSelector((state) => state.general);
  const [editMode, setEditMode] = useState(false);
  const [relevantSegments, setRelevantSegments] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);


  const handleCheckboxSelection = (index) => {
    setSelectedSegments(
      selectedSegments.includes(index)
        ? selectedSegments.filter((i) => i !== index)
        : [...selectedSegments, index]
    );
  };

  useEffect(() => {
    let segments = transcriptionData ? transcriptionData.original_transcription : [];
    if (editMode) {
      setRelevantSegments(segments);
      const index = utils.getNewSegmentIndex(segments, currentTime);
      setCurrentIndex(index);
      setAbsoluteIndex(index);
    } else {
      const [rSegments, index, absoluteIndex] = utils.getRelevantSegments(segments, currentTime, currentAbsoluteIndex);
      setCurrentIndex(index);
      setAbsoluteIndex(absoluteIndex);
      setRelevantSegments(rSegments);
    }
  }, [currentTime, transcriptionData, editMode]);

  return (
      <div className='transcription-section'>
        {transcriptionData?.transcription_status === COMPLETED && (
          <ul className='transcription-segment'>
            {relevantSegments?.map(({key, text, speaker}, index) => {
              return (
                <TranscriptionSegment
                  key={key}
                  canEdit={editMode}
                  segmentTime={key}
                  segmentSpeaker={speaker}
                  segmentText={text?.replace(/^'|'$/g, '')}
                  editMode={editMode}
                  isActive={index === currentIndex}
                  index={index}
                  speakers={transcriptionData?.speakers}
                  speakerMap={transcriptionData?.speakerMap}
                  handleCheckboxSelection={() => {
                    handleCheckboxSelection(index);
                  }}
                />
              );
            })}
          </ul>
        )}
      </div>
  );
}

export default TranscriptionSection;
