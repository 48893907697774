import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { utils } from 'common';
import { COMPLETED, FILE_TYPES } from 'common/constants';
import { EditableSummarySection } from 'components';
import 'components/summary/_summary.scss';
import { generalActions } from "../../reduxStore/reducers/generalSlice";

function Summary({editSummary, showOriginalSummary}) {
  const dispatch = useDispatch();

  const {transcriptionData, summaryData, originalSummaryData} = useSelector((state) => state.general);
  const [summary, setSummary] = useState(summaryData);

  const handleAddSection = (heading) => {
    const newSummary = _.cloneDeep(summary);
    newSummary[heading][''] = [''];
    setSummary(newSummary);
  };

  const getSummaryItemsToRender = (heading, data) => {
    if (!data) {
      return null;
    } else if (typeof data === 'object') {
      return (
        <>
          {Object.keys(data).map((key, index) => {
            return (
              <EditableSummarySection
                key={`${index}-${key}`}
                heading={heading}
                subheading={key}
                data={data[key]}
                editSummary={editSummary}
                summary={summary}
                setSummary={setSummary}
              />
            );
          })}
          {editSummary ? (
            <div
              className='add-section'
              onClick={editSummary ? () => handleAddSection(heading) : null}
            >
              Add {utils.getIndefiniteArticle(heading)}{' '}
              {utils.singularize(utils.snakeToTitleCase(heading))}
            </div>
          ) : null}
        </>
      );
    } else {
      return (
        <EditableSummarySection
          heading={heading}
          data={data}
          editSummary={editSummary}
          summary={summary}
          setSummary={setSummary}
        />
      );
    }
  };

  useEffect(() => {
    setSummary(showOriginalSummary ? originalSummaryData : summaryData);
  }, [summaryData, showOriginalSummary]);

  useEffect(() => {
    if (!editSummary && !showOriginalSummary) {
      setSummary(summaryData);
      dispatch(generalActions.cancelSummaryChanges());
    }
  }, [editSummary]);

  return (
    <div className='summary'>
      <div className='summary-section'>
        {transcriptionData?.summary_status === COMPLETED && (
          <>
            <div className='summary-metadata'>
              <div>
                <b>Title: </b>
                {transcriptionData?.title}
              </div>
              <div>
                <b>File Type: </b>
                {FILE_TYPES[transcriptionData?.file_type]}
              </div>
              <div>
                <b>Duration: </b>
                {transcriptionData?.audio_duration}
              </div>
              {transcriptionData?.start_at && (
                <>
                  <div>
                    <b>Date: </b>
                    {transcriptionData?.start_at.split('T')[0]}
                  </div>
                  <div>
                    <b>Time: </b>
                    {transcriptionData?.start_at.split('T')[1].substring(0, 8)}
                  </div>
                </>
              )}
            </div>
            <div className='summary-metadata'>
              <div>
                <b>Speakers: </b>
                {(transcriptionData?.speakers?.map((speaker) => speaker.name) || []).join(', ')}
              </div>
            </div>
            <div className='summary-body'>
              {summary &&
                Object.entries(summary)?.map(([key, value]) => {
                  if (value === 'NOT AVAILABLE' || value === '' || value === null) {
                    return null;
                  }
                  return (
                    <div className='summary-content'>
                      <span className='summary-head'>{utils.snakeToTitleCase(key)}:</span>
                      <div className='summary-sub-text'>{getSummaryItemsToRender(key, value)}</div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
      {transcriptionData?.last_summary_updated_at ? (
        <div className='modified-datetime'>
          Last updated at {utils.convertDTStrToDT(transcriptionData?.last_summary_updated_at)}
        </div>
      ) : null}
    </div>
  );
}

export default Summary;
