import React from 'react';
import { Modal } from 'react-bootstrap';
import 'components/modals/_customModal.scss';

function CustomModal({
                       show, title, description, cancelBtnTitle = 'Cancel',
                       handleCancel, confirmBtnTitle = 'Confirm', handleConfirm, isDanger = true
                     }) {
  return (
    <Modal show={show} onHide={handleCancel} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {description}
      </Modal.Body>
      <Modal.Footer>
        <button className='simple-btn' onClick={handleCancel}>
          {cancelBtnTitle}
        </button>
        <button className={isDanger ? 'danger-btn' : 'action-btn'} onClick={handleConfirm}>
          {confirmBtnTitle}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomModal;
