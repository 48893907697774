import React from 'react';
import 'components/dropdown/_dropdown.scss';

function Dropdown({ title, options }) {
  return (
    <div className='dropdown'>
      <span className='dropdown-title'>{title}</span>
      <div className='dropdown-content'>
        {options.map((option, index) => {
          return option;
        })}
      </div>
    </div>
  );
}

export default Dropdown;
