import { useSelector } from 'react-redux';
import 'components/meetingMinutes/_meetingMinutes.scss';
import { utils } from 'common';
import React, { useRef, useState } from 'react';
import { IconChevronDown, IconChevronUp } from 'assets/icons';
import { COMPLETED } from 'common/constants';


function MOMSection({title, body}) {
  const [isOpen, setIsOpen] = useState(true);
  const contentRef = useRef(null);

  const getMOMItemsToRender = (data) => {
    if (!data) {
      return null;
    } else if (Array.isArray(data)) {
      return data.map((item) => {
        return getMOMItemsToRender(item);
      });
    } else if (typeof data === 'object') {
      return Object.keys(data).map((key, index) => (<div key={index} className='mom-sub-text'>
        <span className='mom-head'>{utils.snakeToTitleCase(key)}:</span>
        {getMOMItemsToRender(data[key])}
      </div>));
    } else {
      return <p className='mom-sub-text'>{data}</p>;
    }
  };

  return (body ? <div className='mom-section'>
    <div className='section-head' onClick={() => setIsOpen(!isOpen)}>
      <div className='section-title'>
        <h5>{title}</h5>
      </div>
      <div className='section-controls'>
          <span className='dropdown-icon'>
          <img src={isOpen ? IconChevronUp : IconChevronDown} alt='toggle'/>
        </span>
      </div>
    </div>
    <div ref={contentRef} className={`section-body ${isOpen ? 'open' : ''}`}>
      {getMOMItemsToRender(body)}
    </div>
  </div> : null);
}

export default function MeetingMinutes({id}) {
  const {transcriptionData} = useSelector((state) => state.general);

  return (<div className='mom'>
    <div className='mom-sections'>
      {transcriptionData?.summary_status === COMPLETED && transcriptionData?.mom && Object.entries(utils.parseJSONData(transcriptionData?.mom))?.map(([key, value]) => {
        return <MOMSection key={key} title={key} body={value}/>
      })}
    </div>
  </div>);
}
