import React, { useState } from 'react';
import { IconCheck, IconHero } from 'assets/icons';
import 'pages/landing/_landing.scss';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { routes } from 'common';
import { useSelector } from 'react-redux';
import { RatingModal } from 'components';

function Landing() {
  const {isUserLoggedIn} = useSelector((state) => state.auth);

  const location = useLocation();
  const rating = location.state?.rating;

  const [showRatingModal, setShowRatingModal] = useState(!!rating);

  return (<div className='landing'>
    <div className='custom-container'>
      <div className='texts'>
        <div className='header'>
          <h1>
            Get the most out of your <br/>meetings with <span>Textalize</span>
          </h1>
          <div className='sub-points'>
            <span>
              <img src={IconCheck} alt='check' loading='lazy'/>
              Add Textalize to your meetings or upload meeting recordings
            </span>
            <span>
              <img src={IconCheck} alt='check' loading='lazy'/>
              Get bilingual transcriptions & concise summary notes
            </span>
          </div>
        </div>
        {isUserLoggedIn && (<div className='upload-section'>
          <Link className='upload-btn' to={routes.UPLOAD}>
            Upload File
          </Link>
        </div>)}
      </div>
      <div className='hero-img'>
        <img src={IconHero} alt='hero' loading='lazy'/>
      </div>
    </div>
    <RatingModal rating={rating} show={showRatingModal} handleClose={() => setShowRatingModal(false)}/>
  </div>);
}

export default Landing;
